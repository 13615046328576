import { convertNumberInK } from './convertNumberInK';

export const getItems = (
  typeOfSummary: 'checkout' | 'accept-offer',
  shippingCost?: number,
  insurance?: number,
  fees?: number,
  price?: number,
  currency?: string,
) => [
  price && {
    title: 'Item price',
    value:
      currency === 'USD'
        ? `$ ${convertNumberInK(price, 'notRound', currency)}`
        : convertNumberInK(price, 'notRound', currency),
  },
  typeof shippingCost === 'number' && {
    title: 'Shipping',
    value: shippingCost,
  },
  insurance
    ? {
        title: 'Insurance',
        value:
          currency === 'USD'
            ? `$ ${convertNumberInK(insurance, 'notRound', currency)}`
            : convertNumberInK(insurance, 'notRound', currency),
      }
    : null,
  fees && {
    title: `Fees (${typeOfSummary === 'checkout' ? '2.5' : '2'} %)`,
    value:
      currency === 'USD'
        ? `$ ${convertNumberInK(fees, 'notRound', currency)}`
        : convertNumberInK(fees, 'notRound', currency),
  },
];
